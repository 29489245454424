import axios from "../core/axios";
import { paramsParser } from "../core/paramsParser";
import { assertSuccess } from "../core/assert";
import { Headers } from "./generic";
import type {
  Collection,
  CollectionResult,
  MessageResult,
  RatingEntity,
  RatingListParams,
  RatingPostBody,
  RatingPutBody,
  Result,
  TitleRatingEntity,
} from "../types";

export class Rating {
  static async search(
    params: RatingListParams = {},
    auth?: string,
  ): Promise<Collection<RatingEntity>> {
    const res = await axios.get<CollectionResult<RatingEntity>>(
      "/rating" + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(res.data);
  }

  static async getTitleRatings(
    titleId: string,
    params: RatingListParams = {},
  ): Promise<TitleRatingEntity> {
    const res = await axios.get<Result<TitleRatingEntity>>(
      `/title/${titleId}/rating` + paramsParser(params),
    );

    return assertSuccess(res.data).data;
  }

  static async create(
    body: RatingPostBody,
    auth: string,
  ): Promise<RatingEntity> {
    body.message = body.message
      ? Object.fromEntries(
          Object.entries(body.message).map(([lang, text]) => [
            lang,
            text.trim(),
          ]),
        )
      : undefined;

    const res = await axios.post<Result<RatingEntity>>(
      `/title/${body.titleId}/rating`,
      body,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(res.data).data;
  }

  static async update(
    body: RatingPutBody,
    auth: string,
  ): Promise<RatingEntity> {
    body.message = body.message
      ? Object.fromEntries(
          Object.entries(body.message).map(([lang, text]) => [
            lang,
            text.trim(),
          ]),
        )
      : undefined;

    const res = await axios.put<Result<RatingEntity>>(
      `/rating/${body.id}?includes[]=user`,
      body,
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(res.data).data;
  }

  static async delete(ratingId: string, version: number, auth: string) {
    const resp = await axios<MessageResult<"Rating was deleted.">>(
      `/rating/${ratingId}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
        data: { version },
      },
    );

    return assertSuccess(resp.data).result === "ok";
  }
}
